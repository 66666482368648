import React, { useState } from 'react';
import { Tooltip } from 'antd';
import ProductDataImage from './ProductDataImage';
import { shortenString } from '../../../helpers';

import styles from './ProductDataList.module.css';

export const ProductDataList = ({
  productData,
  isDraggable = false,
  onDraggingElementFinish,
}) => {
  const [notAvailableImages, setNotAvailableImages] = useState([]);

  const mapping = {
    __ya_old_price: 'str',
    __ya_old_price_numeric: 'number',
    __g_price: 'str',
    __g_sale_price: 'str',
    __g_price_numeric: 'number',
    __g_sale_price_numeric: 'number',
    __sale_price_numeric: 'number',
    __discount: 'number',
    __difference: 'number',
  };

  const pictureIds = {
    PICTURE: 'picture',
    IMAGE: 'image',
    IMAGE_LINK: 'image_link',
    ADD_IMAGE_LINK: 'additional_image_link',
    IMAGE_URL_1: 'Image url 1',
    IMAGE_URL_2: 'Image url 2',
    IMAGE_URL_3: 'Image url 3',
    IMAGE_URL_4: 'Image url 4',
    IMAGE_URL_5: 'Image url 5',
  };

  const pictureIdArray = Object.values(pictureIds);

  const checkImageAvailable = (image) => {
    setNotAvailableImages((prevState) => [...prevState, image]);
  };

  const checkAvailableProduct = (product, isImage) => {
    if (isImage)
      return !notAvailableImages.some((image) => image === product.id);
    if (product.value === 0) return true;
    return Boolean(product.value);
  };

  const getType = (product) => {
    if (product.type !== 'null') {
      return product.type;
    }
    if (product.isAutoField) {
      return 'auto';
    }
    return null;
  };

  return (
    <div className={styles.productTable}>
      {productData.map((product, index) => {
        const isProductIdValid = pictureIdArray.some(prefix => product.id.startsWith(prefix));
        const isImage = isProductIdValid && product.value.startsWith('http');
        let type = getType(product);
        let productTypeTag;
        let productTypeValue;
        const isAvailable = checkAvailableProduct(product, isImage);

        if (!type && type !== 0) {
          type = mapping[product.id];
        }

        switch (type) {
          case 'str':
            productTypeTag = 'productTagStr';
            productTypeValue = 'Str';
            break;
          case 'number':
            productTypeTag = 'productTagNum';
            productTypeValue = 'Num';
            break;
          case 'auto':
            productTypeTag = 'productTagAuto';
            productTypeValue = 'Auto';
            break;
          default:
            productTypeTag = 'productTagAny';
            productTypeValue = 'Any';
            break;
        }

        return (
          <div className={styles.productRow} key={index}>
            <div className={styles.productField}>
              {
                <Tooltip title={product.name.length > 12 ? product.name : ''}>
                  <span className={styles.productName}>
                    {shortenString(product.name, 12)}
                  </span>
                </Tooltip>
              }
              <span
                className={`${styles.productTag} ${styles[productTypeTag]}`}
              >
                {productTypeValue}
              </span>
            </div>
            <div
              className={`${styles.productField} ${isDraggable ? styles.draggableProductField : ''}`}
            >
              <div
                className={`${isDraggable && isAvailable ? styles.draggableProductItem : ''}`}
                draggable={isDraggable && isAvailable}
                onDragEnd={(event) =>
                  onDraggingElementFinish(
                    event,
                    isImage ? 'image' : 'text',
                    product.id,
                  )
                }
              >
                {isImage ? (
                  <ProductDataImage
                    productId={product.id}
                    src={product.value}
                    alt={product.value}
                    checkImageAvailable={checkImageAvailable}
                  />
                ) : (
                  product.value
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
